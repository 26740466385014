import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
// import ShopCategory from "../components/ecwid/category"

class Shipping extends React.Component {
		render() {
			return (
				<Layout
					pathname="/shipping-return-refund-and-cancellation-policy/"
					head={{
						name: 'Shipping, Return/Refund & Cancellation Policy',
						meta: {
							description:
								'Shipping, Return/Refund & Cancellation Policy In-Stock Items - Were able to ship most of our in-stock items within 1 business day of receiving your order and payment information.',
						},
					}}
				>
					<div className="container py-5">
						<div className="row">
							{/*<div className="col-md-4">
							<h2 className="section-heading">Categories</h2>
							<ShopCategory/>
						</div>*/}
							<div className="col-md-8 shop-items mx-auto">
								<div className="page-content">
									<h4 className="section-heading page-mt">
										Shipping, Return/Refund & Cancellation Policy
									</h4>
									<h5>How & When We Ship</h5>
									<p>
										<strong>
											<i>
												<u>In-Stock Items</u>
											</i>
										</strong>
										– We&#39;re able to ship most of our in-stock items within 1
										business day of receiving your order and payment information.
										Exceptions include full, un-cut sheet goods (King Starboard,
										plywood etc) that are too large to ship by UPS or the U.S. Postal
										Service. These must be packed on pallets and sent by truck
										freight. Even then, we can ship within 3 business days of
										completing your transaction.
									</p>
									<p>
										<strong>
											<i>
												<u>Milled Lumber & Sheet Goods</u>
											</i>
										</strong>
										– We can generally cut these into smaller sizes for more
										economical shipping with little or no time delay. But if you need
										big teak boards or a 4&#39; x 8&#39; sheet of something carved up
										into multiple pieces – each with its own precise dimension – that
										spills down into the next category, which is…
									</p>
									<p>
										<strong>
											<i>
												<u>Custom Orders</u>
											</i>
										</strong>
										– Several of our products are built-to-order and/or incorporate
										various buyer options. And a fair number are flat-out custom
										projects. This means we can&#39;t ship it until it&#39;s built,
										and production times will vary according to the level of
										customization and our workload. For the most part, we can build
										and ship things such as boat doors, fish cleaning tables, dinghy
										chocks and the like within 10 business days – and we note
										estimated shipping times wherever it makes sense on our product
										pages. Otherwise, please give us a call, 305-743-4090, to get a
										delivery estimate before you place your order.
									</p>
									<p>
										<strong>
											<i>
												<u>Free Shipping Offers</u>
											</i>
										</strong>
										- Unless otherwise noted, &#34;free&#34; and/or &#34;flat
										rate&#34; shipping offers presented on our web site apply to
										domestic shipping locations only within the contiguous 48 states.
									</p>
									<h5>How & When We Ship</h5>
									<p>
										<strong>
											<i>
												<u>In-Stock Items</u>
											</i>
										</strong>
										– So long as you cancel an order before we have it packed up for
										shipment, you will not be charged a dime. But if it&#39;s all
										packed up and the shipping paperwork is done, there will be a 15%
										restocking fee.
									</p>
									<p>
										<strong>
											<i>
												<u>Custom & Semi-Custom Items</u>
											</i>
										</strong>
										– If you cancel your order before work has begun, no charge for
										anything. However, if we&#39;ve already begun work on your
										project, you will be charged for any CNC design and/or set-up work
										performed; any other labor costs for shop time; and for any
										materials used up to the point of your cancellation. If you cancel
										after we&#39;ve already built your product, please refer to the
										next section.
									</p>
									<h5>Returns/Refunds</h5>
									<p>
										<strong>
											<i>
												<u>Damaged Goods</u>
											</i>
										</strong>
										– We try to make our packaging bullet-proof, and we insure almost
										everything we ship; so if it&#39;s damaged in-transit we can
										recover the cost and you&#39;ll incur no expense as a result.
										However, if your package shows any damage, it&#39;s your
										responsibility to make a note on the carrier&#39;s delivery
										paperwork and/or refuse to accept the shipment. If you do sign for
										any shipment, that means you have accepted it as being in good
										condition. Once you&#39;ve done that we can&#39;t take
										responsibility for any damage you might find later. If you refuse
										a shipment because it appears damaged, you must contact us with 2
										business days – 305-743-4090, or by
										<a href="mailto:john@buckwoodcraft.com" title="email">
											email.
										</a>
									</p>
									<p>
										<strong>
											<i>
												<u>In-Stock Items</u>
											</i>
										</strong>
										– If you buy an in-stock product that we haven&#39;t customized or
										altered for you in any way (hardware items, teak molding &
										accessories, dinghy chocks, standard sheet-good sizes of
										full-sheet, half-sheet or, where offered, quarter-sheet) you can
										return it to us within 30 days in its original, undamaged
										condition and packaging for a full refund less any shipping &
										handling costs, plus a 15% restocking fee. We cannot accept
										returns of sheet goods that have been purchased by the square
										foot, or any items cut or altered to your specifications.
									</p>
									<p>
										<strong>
											<i>
												<u>Custom & Semi-Custom Items</u>
											</i>
										</strong>
										– We go to considerable lengths to make sure built-to-order
										products conform to the specifications you provide, from milling
										sheet goods and/or lumber to specific sizes, to getting any color
										and/or hardware options right. We&#39;ll confirm these with you
										via email before producing such work — and if we mess up, simply
										return the product within 30 days and we&#39;ll refund the full
										purchase price and shipping costs. Here again, though, if we
										accurately build a product to the specifications you give us and
										it turns out to be the wrong size or is otherwise unusable, we
										cannot refund your money.
									</p>
								</div>
							</div>
						</div>
					</div>
				</Layout>
			)
	}

}



export default Shipping

export const categorynodeQuery = graphql`
	query {
		allStrapiCategories(sort: { fields: id, order: ASC }) {
			edges {
				node {
					id
					name
					url
					subcategories {
						name
						url
					}
				}
			}
		}
	}
`
